import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import axios from "axios";
import {APIURL} from "../../config/config";
import {AlertError, AlertSuccess} from "../Alert/Alert";
import BDatePicker from "../BDatePicker";


class CustomerCreditForm extends Component{
    constructor (props) {
        super(props)

        this.state = {
            customer_list: [],
            customer_select: false,
            customer: {},
            customer_id: '',
            bank: [],
            finance_id: 0,
            finance_credit_result: '',
            finance_check_date: null,

            bureau_tracing_id: 0,
            bureau_tracing_count: 0,
            blacklist_bureau_id: 0,
            blacklist_period_id: 0,
            balance_closed_id: 0,
            balance_closing_date: null,

            bureau_tracings: [],
            blacklist_bureaus: [],
            blacklist_periods: [],
            balance_closeds: [],

        }

        // onSubmitCustomerCredit
        this.searchCustomer = this.searchCustomer.bind(this);
        this.getDataCustomerSearch = this.getDataCustomerSearch.bind(this);
        this.onCustomerSelect = this.onCustomerSelect.bind(this);
        this.loadBankDataIsLeasing = this.loadBankDataIsLeasing.bind(this);
        this.onSubmitCustomerCredit = this.onSubmitCustomerCredit.bind(this);
        this.loadCustomerCreditOptionData = this.loadCustomerCreditOptionData.bind(this);
        this.isFillData = this.isFillData.bind(this);
    }

    componentDidMount () {
        this.loadCustomerCreditOptionData()
        this.loadBankDataIsLeasing()
        this.loadInstallmentHistory()

        let form_status = this.props.form_status
        if(form_status === 'edit'){
            let customer_credit = this.props.customer_credit_edit
            let customer = {
                customer_id: customer_credit.customer_id,
                customer_name: customer_credit.customer_name,
                customer_lastname: customer_credit.customer_lastname,
                customer_mobile: customer_credit.customer_mobile,
                customer_card_id: customer_credit.customer_card_id,
                customer_address: customer_credit.customer_address
            };

            this.setState({
                customer_id: customer_credit.customer_id,
                finance_id: customer_credit.finance_id,
                finance_credit_result: customer_credit.finance_credit_result,
                finance_check_date: customer_credit.finance_check_date,
                bureau_tracing_id: customer_credit.bureau_tracing_id,
                bureau_tracing_count: customer_credit.bureau_tracing_count,
                blacklist_bureau_id: customer_credit.blacklist_bureau_id,
                blacklist_period_id: customer_credit.blacklist_period_id,
                balance_closed_id: customer_credit.balance_closed_id,
                balance_closing_date: customer_credit.balance_closing_date,
                customer_select: true,
                customer: customer
            });
        }else {
            let customer_id = this.props.customer_id
            let customer = this.props.customer
            this.setState({
                customer_select: true,
                customer_id: customer_id,
                customer: customer
            })
        }
    }

    searchCustomer () {
        let search_text = this.refs.search_customer_name.value
        //alert(search_text);
        this.getDataCustomerSearch(search_text)
    }

    getDataCustomerSearch (search_text) {
        axios.get(APIURL + '/customer/search/' + search_text)
            .then(res => {
                this.setState({customer_list: res.data})
                // alert (res.data)
            }).catch(error => {
            this.setState({customer_list: []})
        })
    }

    onCustomerSelect (customer, event) {
        this.setState({
            customer_select: true,
            customer: customer,
            customer_id: customer.customer_id,
            customer_list: []
        });
    }

    loadBankDataIsLeasing () {
        // axios.get(APIURL + '/bank/')
        axios.get(APIURL + '/bank/get/is_leasing')
            .then(res => {
                this.setState({bank: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({bank: []})
        })
    }

    loadInstallmentHistory(){
        axios.get(APIURL + '/installment_history')
            .then(res => {
                this.setState({installment_history: res.data})
                //alert (res.data)
            }).catch(error => {
            this.setState({installment_history: []})
        })
    }

    loadCustomerCreditOptionData() {
        axios.get(APIURL + '/customer_credit/option/data')
            .then(res => {

                let data = res.data
                let bureau_tracings = data.bureau_tracings
                let blacklist_bureaus = data.blacklist_bureaus
                let blacklist_periods = data.blacklist_periods
                let balance_closeds = data.balance_closeds

                this.setState({
                    bureau_tracings,
                    blacklist_bureaus,
                    blacklist_periods,
                    balance_closeds
                })

            }).catch(error => {
            this.setState({installment_history: []})
        })
    }

    isFillData(data) {
        let emptyData = {
            "finance_id": 0,
            "finance_credit_result": "",
            "finance_check_date": null,
            "bureau_tracing_id": 0,
            "bureau_tracing_count": 0,
            "blacklist_bureau_id": 0,
            "blacklist_period_id": 0,
            "balance_closed_id": 0,
            "balance_closing_date": null
        }
        let strEmpty = JSON.stringify(emptyData)
        let strNew = JSON.stringify(data)

        return strEmpty !== strNew
    }

    onSubmitCustomerCredit(e) {
        e.preventDefault();

        const {
            bureau_tracing_id,
            bureau_tracing_count,
            blacklist_bureau_id,
            blacklist_period_id,
            balance_closed_id,
            balance_closing_date,
        } = this.state

        let finance_id = this.state.finance_id
        let customer_id = this.state.customer_id
        let finance_credit_result = this.state.finance_credit_result
        let finance_check_date = this.state.finance_check_date
        let username = this.props.username

        if(customer_id === '') {
            AlertError('กรุณาเลือกลูกค้า');
            return
        }

        /* if(finance_id === 0) {
            AlertError('กรุณาเลือกไฟแนนซ์');
            return
        }

        if(finance_check_date === null || finance_check_date === '') {
            AlertError('กรุณาเลือกวันที่เช็คเครดิต');
            return
        }

        if(finance_credit_result === '') {
            AlertError('กรุณากรอกผลการเช็คเครดิต');
            return
        } */

        let data = {
            finance_id,
            finance_credit_result,
            finance_check_date,
            bureau_tracing_id,
            bureau_tracing_count,
            blacklist_bureau_id,
            blacklist_period_id,
            balance_closed_id,
            balance_closing_date,
        }

        if (!this.isFillData(data)) {
            AlertError('กรุณากรอกข้อมูล');
            return;
        }

        data = {...data, customer_id: customer_id, username: username}

        // console.log(data)
        // return

        let form_status = this.props.form_status

        if(form_status === 'add'){
            let url = APIURL +'/customer_credit'
            axios.post(url, data).then(res => {
                let result = res.data;
                if(result === 'success') {
                    AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
                    this.props.closeAndReload()
                }
            })
        } else if(form_status === 'edit') {
            let customer_credit_id = this.props.customer_credit_id
            data = {...data, customer_credit_id: customer_credit_id}
            let url = APIURL +'/customer_credit'
            axios.put(url, data).then(res => {
                let result = res.data;
                if(result === 'success') {
                    AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                    this.props.closeAndReload()
                }
            })
        }

    }

    render() {
        let width = this.props.width
        let {
            bank,
            bureau_tracings,
            blacklist_bureaus,
            blacklist_periods,
            balance_closeds
        } = this.state

        let search_customer_list = this.state.customer_list.map((customer_list, index) => {
            return (
                <tr key={index}>
                    <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
                    <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
                    <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
                    <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
                    <td>
                        <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                                className="btn btn-primary">
                            <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
                        </button>
                    </td>
                </tr>
            )
        });

        let bank_finance_list = bank.map((bank, index) => {
            return (
                <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
            )
        });

        let bureau_tracings_list = bureau_tracings.map((b, index) => {
            return (
                <option key={index} value={b.bureau_tracing_id}>{b.bureau_tracing_name}</option>
            )
        });

        let blacklist_bureaus_list = blacklist_bureaus.map((b, index) => {
            return (
                <option key={index} value={b.blacklist_bureau_id}>{b.blacklist_bureau_name}</option>
            )
        });

        let blacklist_periods_list = blacklist_periods.map((b, index) => {
            return (
                <option key={index} value={b.blacklist_period_id}>{b.blacklist_period_name}</option>
            )
        });

        let balance_closeds_list = balance_closeds.map((b, index) => {
            return (
                <option key={index} value={b.balance_closed_id}>{b.balance_closed_name}</option>
            )
        });

        return (
            <>
             <Modal
                 isOpen={this.props.is_modal}
                 toggle={this.props.toggle}
                 className={'modal-lg '}
                 style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
             >
                 <ModalBody>
                     <div className="card card-accent-primary ">
                         <div className="card-header">
                             <strong className="text-title">{this.props.form_status==='add'? ' เพิ่ม' : ' แก้ไข'}ประวัติการเช็คเครดิต</strong>
                         </div>

                         <form onSubmit={this.onSubmitCustomerCredit}>

                             <div className="card-block">

                             <div className={this.state.customer_select === false ? 'row ' : ' hidden'}>
                                 <div className="controls col-md-12">
                                     <div className="input-group has-success ">
                                         <input
                                             ref="search_customer_name"
                                             placeholder="ค้นหาชื่อลูกค้า"
                                             className="form-control"
                                             size="16"
                                             type="text"/>

                                         <span className="input-group-btn ">
                                   <button className="btn btn-success"
                                           type="button"
                                           onClick={this.searchCustomer.bind(this)}>ค้นหา
                                   </button>
                              </span>
                                     </div>
                                     <div id="show_customer" className="row">
                                         <div className="controls col-md-12">
                                             <table className="table table-striped">
                                                 <tbody>
                                                 {search_customer_list}
                                                 </tbody>
                                             </table>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <div className={this.state.customer_select ? 'row ' : ' hidden'}>

                                 <div className="card-header controls col-md-12 hidden ">
                                     <strong className="text-title">ข้อมูลลูกค้า</strong>
                                 </div>

                                 <div className="card-block has-success">
                                     <div className="row">
                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong> ชื่อลูกค้า </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success ">
                                                         <strong>{this.state.customer.customer_name}</strong></p>
                                                 </div>

                                                 <label className="col-md-3"><strong> นามสกุล </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong> {this.state.customer.customer_lastname} </strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong>
                                                     เลขบัตรประชาชน </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong>{this.state.customer.customer_card_id}</strong></p>
                                                 </div>

                                                 <label className="col-md-3"><strong>
                                                     เบอร์โทรศัพท์ </strong></label>
                                                 <div className="col-md-3">
                                                     <p className="text-justify text-success">
                                                         <strong> {this.state.customer.customer_mobile} </strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                         <div className="col-lg-12">
                                             <div className=" form-group row" style={styles.bottomLine}>
                                                 <label className="col-md-3"><strong>
                                                     ที่อยู่ปัจจุบัน </strong></label>
                                                 <div className="col-md-9">
                                                     <p className="text-justify text-success">
                                                         <strong>{this.state.customer.customer_address}</strong></p>
                                                 </div>
                                             </div>
                                         </div>

                                     </div>
                                 </div>
                             </div>



                             <div className="row">

                                 <div className="form-group col-md-3">
                                     <label htmlFor="bureau_tracing">ติดแท็กซิ่ง </label>
                                     <select className="form-control"
                                             name="bureau_tracing_id"
                                             value={this.state.bureau_tracing_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     bureau_tracing_id: e.target.value
                                                 })
                                             }}
                                     >
                                         {bureau_tracings_list}
                                     </select>
                                 </div>

                                 <div className="form-group col-md-3">
                                     <label htmlFor="bureau_tracing_count">จำนวนครั้งที่ติด </label>
                                     <input type="number"
                                            className="form-control"
                                            name="bureau_tracing_count"
                                            placeholder=""
                                            maxLength={2}
                                            value={this.state.bureau_tracing_count}
                                            onChange={(e) => {
                                                this.setState({
                                                    bureau_tracing_count: e.target.value
                                                })
                                            }}
                                     />
                                 </div>

                                 <div className="form-group col-md-3">
                                     <BDatePicker
                                         isDateFill={0}
                                         name={'finance_check_date'}
                                         value={this.state.finance_check_date}
                                         label={'วันที่เช็คเครดิตล่าสุด '}
                                         onUpdateDate={(buddhist_date, christ_date) => {
                                             this.setState({
                                                 finance_check_date: christ_date
                                             })
                                         }}
                                     />
                                 </div>

                                 <div className="form-group col-md-3">
                                     &nbsp;
                                 </div>

                                 <div className="form-group col-md-3">
                                     <label htmlFor="blacklist_bureau_id">
                                         ติดเครดิตบูโร
                                     </label>
                                     <select className="form-control"
                                             name="blacklist_bureau_id"
                                             value={this.state.blacklist_bureau_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     blacklist_bureau_id: e.target.value
                                                 })
                                             }}
                                     >
                                         {blacklist_bureaus_list}
                                     </select>
                                 </div>

                                 <div className="form-group col-md-6">
                                     <label htmlFor="finance_credit_result">ผลการเช็คเครดิต/เรื่องที่ติดเครดิตบูโร </label>
                                     <input type="text"
                                            className="form-control"
                                            name="finance_credit_result"
                                            placeholder=""
                                            maxLength={50}
                                            value={this.state.finance_credit_result}
                                            onChange={(e) => {
                                                this.setState({
                                                    finance_credit_result: e.target.value
                                                })
                                            }}
                                     />
                                 </div>

                                 <div className="form-group col-md-3">
                                     <label htmlFor="blacklist_period_id">
                                         ระยะเวลาที่ติดแบล็คลิสต์
                                     </label>
                                     <select className="form-control"
                                             name="blacklist_period_id"
                                             value={this.state.blacklist_period_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     blacklist_period_id: e.target.value
                                                 })
                                             }}
                                     >
                                         {blacklist_periods_list}
                                     </select>
                                 </div>


                                 <div className="form-group col-md-3">
                                     <label htmlFor="balance_closed_id">
                                         ปิดยอดแล้ว
                                     </label>
                                     <select className="form-control"
                                             name="balance_closed_id"
                                             value={this.state.balance_closed_id}
                                             onChange={(e) => {
                                                 this.setState({
                                                     balance_closed_id: e.target.value
                                                 })
                                             }}
                                     >
                                         {balance_closeds_list}
                                     </select>
                                 </div>

                                 <div className="form-group col-md-3">
                                     <BDatePicker
                                         isDateFill={0}
                                         name={'balance_closing_date'}
                                         value={this.state.balance_closing_date}
                                         label={'วันที่ปิดยอด '}
                                         onUpdateDate={(buddhist_date, christ_date) => {
                                             this.setState({
                                                 balance_closing_date: christ_date
                                             })
                                         }}
                                     />
                                 </div>

                                 <div className="form-group col-md-3">
                                     <label htmlFor="finance_id">ชื่อไฟแนนซ์</label>
                                     <select className="form-control"
                                             name="finance_id"
                                             value={this.state.finance_id}
                                            onChange={(e) => {
                                                this.setState({
                                                    finance_id: e.target.value
                                                })
                                            }}
                                     >
                                         <option key={0} value={0}>--กรุณาเลือก--</option>
                                         {bank_finance_list}
                                     </select>
                                 </div>

                             </div>



                         </div>

                             <div className="card-footer text-right">

                                 <button type="button"
                                         onClick={()=> this.props.toggle()}
                                         className="btn btn-danger mr-2">
                                     <i className="fa fa-close"/>  &nbsp;&nbsp; ปิด &nbsp;&nbsp;
                                 </button>

                                 {
                                     this.props.form_status === 'add' ?
                                         <button type="submit" className="btn btn-success">
                                             <i className="fa fa-save"/> &nbsp;&nbsp; เพิ่ม &nbsp;&nbsp;
                                         </button>
                                         :
                                         <button type="submit" className="btn btn-warning">
                                             <i className="fa fa-pencil"/> &nbsp;&nbsp; แก้ไข &nbsp;&nbsp;
                                         </button>
                                 }

                             </div>
                         </form>
                     </div>
                 </ModalBody>
             </Modal>
            </>
        )
    }
}

const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    }
}

export default CustomerCreditForm
