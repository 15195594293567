import React, { Component } from 'react'
import axios from 'axios'
import {connect} from 'react-redux';
// import MaskedInput  from 'react-maskedinput'
import { format } from "date-fns";
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import { APIURL } from '../../../config/config'
import NumberFormat from 'react-number-format';
import Switch from 'react-switch'
import { NumberUtil } from '../../../utils/number-util'
import { AlertError, AlertSuccess, AlertWarning } from '../../Alert/Alert'
moment.locale('th');

class ReceiptsForm extends Component {
    constructor (props) {
        super(props)
        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            receipts:'',
            branch:[],
            // expense_type:[],
            income_expense_item:[],
            receipts_amount:0,
            startDateReceipts: new Date() ,//moment().format('YYYY-MM-DD'),
            receipts_name:'',
            receipts_image:'',
            user: {},
            w_receipts_image_file: false,
            receipts_no : '',
            receipts_company : '',
          // new
          company_setting: null,
          is_vat: null,
          vatPercent: null,
          vat: 0,
          include_vat: 0,
          receipts_amount_vat: 0,
          receipts_id: 0
        }
        this.handleDateReceipts = this.handleDateReceipts.bind(this)
        this.convertBBtoCC = this.convertBBtoCC.bind(this)
        // this.onMaskedDateReceipts = this.onMaskedDateReceipts.bind(this)
        this.handleReceiptsNameChange = this.handleReceiptsNameChange.bind(this)
        this.handleOnSubmitReceipts = this.handleOnSubmitReceipts.bind(this)
        this.handleIncomeExpenseItemChange = this.handleIncomeExpenseItemChange.bind(this)
        this.handleReceiptsNoChange = this.handleReceiptsNoChange.bind(this);
        this.handleReceiptsCompanyChange = this.handleReceiptsCompanyChange.bind(this);
        this.loadCompanySetting = this.loadCompanySetting.bind(this);
    }

   async componentDidMount() {
      await this.loadCompanySetting()
      // this.loadData()
     await this.loadBranchData()
     // await this.loadExpenseTypeData()
     await this.loadIncomeExpenseItemData()

      let form_status = this.props.form_status

    if(form_status === 'edit') {
      let receipts_selected = this.props.receipts_selected
      let receipts_date = receipts_selected.receipts_date
      // let receipts_date = format(new Date(receipts_date_str), 'yyyy-MM-dd')

      let branch_id = String(receipts_selected.branch_id)
      let income_expense_item_id = String(receipts_selected.income_expense_item_id)

      this.refs.branch_id.value = branch_id
      this.refs.income_expense_item_id.value = income_expense_item_id

      let receipts_amount_vat = receipts_selected.receipts_amount_vat === null ? 0 :  Number(receipts_selected.receipts_amount_vat)

      this.setState({
        startDateReceipts: new Date(receipts_date),
        receipts_id: Number(receipts_selected.receipts_id),
        receipts_name: receipts_selected.receipts_name,
        receipts_no:  receipts_selected.receipts_no,
        receipts_company:  receipts_selected.receipts_company,
        include_vat:  Number(receipts_selected.include_vat),
        receipts_amount:  Number(receipts_selected.receipts_amount),
        receipts_amount_vat: receipts_amount_vat,
      })


    }


  }

  loadCompanySetting() {
    axios.get(APIURL + '/company_setting/1').then(res => {
      const data = res.data;
      const company_setting = data.company_setting;
      const is_vat = company_setting.is_vat;
      const vatPercent = is_vat === 1 ? company_setting.vat/100.0 : 0;
      const vat = is_vat === 1 ? company_setting.vat : 0;

      this.setState({
        company_setting: company_setting,
        is_vat,
        vatPercent,
        vat: vat
      });
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

    handleOnSubmitReceipts(event) {
        event.preventDefault()
        let receipts_date = moment(this.state.startDateReceipts).format('YYYY-MM-DD')
        let branch_id = event.target.branch_id.value
        let income_expense_item_id = event.target.income_expense_item_id.value
        let receipts_name = event.target.receipts_name.value
        let receipts_amount = event.target.receipts_amount.value
        //let expense_image_file = event.target.expense_image_file.value

        // expense_type_id = 3 = รายรับของเต็นท์รถ
        let expense_type_id = 3

        let receipts_no = event.target.receipts_no.value
        let receipts_company = event.target.receipts_company.value

       /* try {
            if(event.target.receipts_image_file.files[0].name === undefined){
                this.setState({
                    w_receipts_image_file: false
                });
            }
        }catch (ex){
            this.setState({
                w_receipts_image_file: true
            });
            return;
        } */

        if(receipts_amount===''){
            AlertWarning('กรุณากรอกจำนวนเงิน')
            this.refs.receipts_amount.focus()
            return ;
        }

        if(income_expense_item_id===''){
            AlertWarning('กรุณาเลือกหมวดหมู่ค่าใช้จ่าย')
            this.refs.income_expense_item_id.focus()
            return ;
        }

        if(branch_id===''){
            AlertWarning('กรุณาเลือกสาขา')
            this.refs.branch_id.focus()
            return ;
        }

      let include_vat = this.state.include_vat
      let vat = this.state.vat
      let receipts_amount_vat = this.state.receipts_amount_vat
      let receipts_id = this.state.receipts_id
      let form_status = this.props.form_status

      if(event.target.receipts_image_file.files.length === 0) { // nofile
        let data = {
          receipts_date: receipts_date,
          branch_id: branch_id,
          receipts_amount: receipts_amount,
          income_expense_item_id: income_expense_item_id,
          receipts_name: receipts_name,
          expense_type_id: expense_type_id,
          user_created: this.state.user.user,
          user_updated: this.state.user.user,
          receipts_image_file: 'nophoto.png',
          receipts_no: receipts_no,
          receipts_company: receipts_company,
          include_vat,
          vat,
          receipts_amount_vat,
          receipts_id,
          form_status
        }


        axios.post(APIURL + '/receipts/nofile/', data)
          .then(res =>{
            if (res.status === 200) {
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.props.onReloadReceipts();
              this.props.onToggle();
            }

          })
          .catch(error => {
            console.log(error)

          })

      } else {
        let filename = event.target.receipts_image_file.files[0].name;
        //alert (filename)
        //return;
        if (!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)) {
          //alert(event.target.car_repair_file.files[0].type);
          this.setState({
            w_receipts_image_file: true
          });
          return;
        }

        const data = new FormData();
        data.append('file', event.target.receipts_image_file.files[0], event.target.receipts_image_file.files[0].name);
        data.append('receipts_date', receipts_date);
        data.append('branch_id', branch_id);
        data.append('income_expense_item_id', income_expense_item_id);
        data.append('receipts_name', receipts_name);
        data.append('receipts_amount', receipts_amount);
        data.append('expense_type_id', expense_type_id);
        data.append('user_created', this.state.user.user);
        data.append('user_updated', this.state.user.user);
        data.append('receipts_no', receipts_no);
        data.append('receipts_company', receipts_company);
        data.append('include_vat', include_vat);
        data.append('vat', vat);
        data.append('receipts_amount_vat', receipts_amount_vat);
        data.append('receipts_id', receipts_id);
        data.append('form_status', form_status);

        axios.post(APIURL + '/receipts/', data)
          .then(res => {

            if (res.status === 200) {
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.props.onReloadReceipts();
              this.props.onToggle();
            }

          })
          .catch(error => {
            console.log(error)
          })
      }
    }

    handleDateReceipts(date) {
        // startDateReceipts: moment(date).format('YYYY-MM-DD')
        this.setState({
            startDateReceipts: new Date(date)
        });
    }

    /*onMaskedDateReceipts(e){
        let buddhist_date = e.target.value ;
        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.handleDateReceipts(cc_date)
        }
    }*/

    convertBBtoCC(buddhist_date) { //dd-mm-yyyy to yyyy-mm-dd
        let date_arr=buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = date_arr[2]-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    handleIncomeExpenseItemChange (event) {
        var income_expense_item_name = event.target.options[event.target.selectedIndex].text
        this.setState({
            receipts_name: income_expense_item_name,
        })
    }

    handleReceiptsNameChange(){
        var receipts_name = this.refs.receipts_name.value
        this.setState({
            receipts_name : receipts_name
        });
    }

    handleReceiptsNoChange(){
        let receipts_no = this.refs.receipts_no.value
        this.setState({
            receipts_no : receipts_no
        });
    }

    handleReceiptsCompanyChange(){
        let receipts_company = this.refs.receipts_company.value
        this.setState({
            receipts_company : receipts_company
        });
    }

    // loadData () {
    //     axios.get(APIURL + '/receipts/')
    //         .then(res => {
    //             //alert("res"+res);
    //             this.setState({lists: res.data, })
    //         }).catch(error => {
    //         //alert("error"+error.message);
    //         this.setState({lists: []})
    //     })
    // }

   async loadBranchData () {
       await axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branch: res.data})
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    // loadExpenseTypeData () {
    //     axios.get(APIURL + '/expense_type/')
    //         .then(res => {
    //             this.setState({expense_type: res.data})
    //         }).catch(error => {
    //         this.setState({expense_type: []})
    //     })
    // }

    async loadIncomeExpenseItemData () {
        await axios.get(APIURL + '/income_expense_item/get/income')
            .then(res => {
                if( res.status === 200)
                 this.setState({income_expense_item: res.data})
            }).catch(error => {
            this.setState({income_expense_item: []})
        })
    }

    render(){
        //const { history,user } = this.props
      const {
        form_status
      } = this.props

      let branch_list = this.state.branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
            return(
                <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
            )
        })

        return (
            <div className={form_status === 'add' ? "card card-accent-primary" : "card card-accent-warning" }>
                <form action="" method="post" onSubmit={this.handleOnSubmitReceipts.bind(this)}>
                    <div className="card-header">
                        <strong className="text-title">
                          {
                            form_status === 'add' ? 'เพิ่มข้อมูลรายรับ' : 'แก้ไขข้อมูลรายรับ'
                          }
                          </strong>
                    </div>

                    <div className="card-block">
                        <div className="row">

                            <div className="form-group col-sm-6">
                                <label htmlFor="receipts_date_text">วันที่รับเงิน</label>
                                {/*<MaskedInput
                                    mask="11-11-1111"
                                    name="receipts_date_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateReceipts}
                                    className="form-control"
                                />*/}
                                <br/>
                                <DatePicker
                                    type="text"
                                    className="form-control"
                                    name="expense_date_text"
                                    placeholder=""
                                    selected={this.state.startDateReceipts}
                                    onChange={this.handleDateReceipts}
                                    dateFormat="yyyy-MM-dd"
                                />

                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        name="branch_id"
                                        ref="branch_id"
                                >
                                    {branch_list}
                                </select>
                            </div>


                            <div className="form-group col-sm-6">
                                <label htmlFor="income_expense_item">หมวดรายรับ</label>
                                <select
                                    className="form-control"
                                    id="income_expense_item_id"
                                    name="income_expense_item_id"
                                    ref="income_expense_item_id"
                                    onChange={this.handleIncomeExpenseItemChange}
                                >
                                    {income_expense_item_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="receipts_name">รายละเอียดรายรับ (พิมพ์เพิ่มเติมได้)</label>
                                <input type="text"
                                       className="form-control"
                                       id="receipts_name"
                                       ref="receipts_name"
                                       value={this.state.receipts_name || ''}
                                       placeholder=""
                                       onChange={this.handleReceiptsNameChange}
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="receipts_no">เลขที่บิล</label>
                                <input type="text"
                                       className="form-control"
                                       id="receipts_no"
                                       ref="receipts_no"
                                       value={this.state.receipts_no || ''}
                                       placeholder=""
                                       onChange={this.handleReceiptsNoChange}
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="receipts_company">บริษัทคู่ค้า</label>
                                <input type="text"
                                       className="form-control"
                                       id="receipts_company"
                                       ref="receipts_company"
                                       value={this.state.receipts_company || ''}
                                       placeholder=""
                                       onChange={this.handleReceiptsCompanyChange}
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="receipts_amount" className="form-inline">
                                  ยอดรายรับ &nbsp;
                                  {
                                    this.state.include_vat === 1 ? '*รวม vat': '*ไม่รวม vat'
                                  }
                                  &nbsp;
                                  <div style={{marginBottom: -2}}>
                                    <Switch height = {20} width ={48}
                                            onChange={(checked) => {
                                              if (checked === false) {
                                                this.setState({include_vat: 0})
                                              } else {
                                                this.setState({include_vat: 1})
                                              }
                                            }}
                                            checked={this.state.include_vat === 1}
                                    />
                                  </div>
                                </label>
                                <input
                                    type="text"
                                    className="form-control hidden"
                                    name="receipts_amount"
                                    placeholder=""
                                    value={this.state.receipts_amount}
                                    onChange={() => {}}
                                />
                                <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="ยอดรายรับ"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.receipts_amount}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({receipts_amount: value})
                                    }}
                                />
                            </div>

                            <div className="form-group col-sm-6">
                              <label htmlFor="expense_amount_vat">VAT(ถ้ามี)&nbsp;</label>
                              <button type="button" onClick={() => {

                                // const floor2Digit = (value, exp) => NumberUtil.decimalAdjust("floor", value, exp);

                                let is_vat = this.state.is_vat
                                if (is_vat === 0) {
                                  AlertError('ยังไม่ได้ตั้งค่าใช้งานระบบ VAT กรุณาติอต่อ bosscar')
                                  return
                                }


                                if (this.state.include_vat === 0) {
                                  let receipts_amount = this.state.receipts_amount === '' ? 0 : Number(this.state.receipts_amount);
                                  let vatAmount = receipts_amount * this.state.vatPercent;
                                  let vat_fix = parseFloat(String(vatAmount)).toFixed(2);
                                  // let vat_fix = floor2Digit(vatAmount, -2);

                                  this.setState({
                                      receipts_amount_vat : vat_fix
                                  });
                                } else if (this.state.include_vat === 1) {
                                  // มูลค่า VAT = ราคารวม VAT x 7/107. ตัวอย่างการ
                                  let receipts_amount = this.state.receipts_amount === '' ? 0 : Number(this.state.receipts_amount);
                                  let vatNumber = Number(this.state.company_setting.vat) // = 7
                                  let vatDivide = 100 + vatNumber // 107
                                  let vatAmount = receipts_amount * (vatNumber / vatDivide)
                                  let vat_fix = parseFloat(String(vatAmount)).toFixed(2);
                                  // let vat_fix = floor2Digit(vatAmount, -2);

                                  this.setState({
                                    receipts_amount_vat: vat_fix
                                  });
                                }

                              }} >
                                {this.state.include_vat === 1 ? 'ถอด VAT' : 'คำนวณ VAT'}
                              </button>

                              <NumberFormat
                                className="form-control"
                                style={{marginTop: -3}}
                                thousandSeparator={true}
                                prefix={'฿'}
                                placeholder="VAT ขาย"
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.receipts_amount_vat}
                                onValueChange = {(values) => {
                                  const {value} = values
                                  this.setState({receipts_amount_vat: value})
                                }}
                              />

                            </div>

                            <div className="form-group col-md-12">
                                <label htmlFor="receipts_image_file">เอกสารแนบ </label>
                                <input type="file"
                                       id="receipts_image_file"
                                       name="receipts_image_file"
                                       accept="image/*"
                                       className="form-control"
                                       onChange={(e)=>{
                                           const { target } = e
                                           if(target.value.length > 0){
                                               this.setState({
                                                   w_receipts_image_file: false
                                               });
                                           } else {
                                               //target.reset();
                                               this.setState({
                                                   w_receipts_image_file: true
                                               });
                                           }

                                       }}
                                />
                                { this.state.w_receipts_image_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

                            </div>

                        </div>
                    </div>

                    <div className="card-footer text-right">
                        <button type="reset"
                                onClick={this.props.onToggle}
                                className="btn btn-sm btn-danger mr-2">
                            <i className="fa fa-refresh"/> ยกเลิก
                        </button>

                      {
                        form_status === 'add' ?
                          <button type="submit"
                                  className="btn btn-sm btn-primary">
                            <i className="fa fa-save"/> บันทึก
                          </button>
                          :
                          <button type="submit"
                                  className="btn btn-sm btn-warning">
                            <i className="fa fa-pencil"/> แก้ไข
                          </button>
                      }

                        {/*<input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>*/}
                    </div>

                </form>
            </div>
        )

    }
}

/*const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    }
}*/

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ReceiptsForm);
