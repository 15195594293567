import React, { Component } from 'react';
import Loading from '../Loading';
import axios from 'axios';
import { APIURL } from '../../config/config';
import { th } from 'date-fns/locale';
import Switch from 'react-switch';

class FinanceCriteria extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      banks: [],
      bank_change_books: [],
      bank_car_adapts: [],
      change_book_statuses: [],
      car_adapt_statuses: [],
      car_leasing_settings: [],
    }
    this.loadBankDataIsLeasingAndFinanceCriteria = this.loadBankDataIsLeasingAndFinanceCriteria.bind(this)
    this.onChangeSwitchChangeBook = this.onChangeSwitchChangeBook.bind(this)
    this.onChangeSwitchCarAdapt = this.onChangeSwitchCarAdapt.bind(this)
    this.addOrUpdateBankChangeBooks = this.addOrUpdateBankChangeBooks.bind(this)
    this.addOrUpdateBankCarAdapt = this.addOrUpdateBankCarAdapt.bind(this)
    this.onChangeCarLeasingName = this.onChangeCarLeasingName.bind(this)
  }

  componentDidMount () {
    this.loadBankDataIsLeasingAndFinanceCriteria()
  }

  loadBankDataIsLeasingAndFinanceCriteria () {
    // axios.get(APIURL + '/bank/')
    axios.get(APIURL + '/bank/get/finance_criteria')
      .then(res => {
        if (res.data.banks.length !== 0) {
          // let _ = require('lodash');
          // let bank_order = _.orderBy(res.data, ['bank_name'], ['asc']);

          let data = res.data
          let banks = data.banks
          //
          let bank_change_books = data.bank_change_books
          let bank_change_books_new = []
          let change_book_statuses = data.change_book_statuses
          //
          let bank_car_adapts = data.bank_car_adapts
          let bank_car_adapts_new = []
          let car_adapt_statuses = data.car_adapt_statuses

          banks.map((bank, index) => {
            let bank_id = bank.bank_id
            // การเปลี่นยเล่ม
            let bank_change_book_filter = bank_change_books.filter(change_book => change_book.bank_id === bank_id)
            if (bank_change_book_filter.length === 0) {
              for(let i=0; i < change_book_statuses.length  ; i++) {
                let change_book_status_id = change_book_statuses[i].change_book_status_id
                let change_book_status_name = change_book_statuses[i].change_book_status_name
                let tmp_change_book = {
                  bank_id: bank_id,
                  change_book_status_id: change_book_status_id,
                  change_book_status_name: change_book_status_name,
                  not_accepting: 0,
                  is_edit: 0,
                  status_text: 'new'
                }
                bank_change_books_new.push(tmp_change_book)
              }
              // bank_change_books_new.push(bank_change_book_filter)
            }else{
              let bank_id = bank_change_book_filter[0].bank_id
              for(let i=0; i < change_book_statuses.length  ; i++) {
                let change_book_statuses_db = change_book_statuses[i]
                let change_book_status_id = change_book_statuses_db.change_book_status_id
                let change_book_status_name = change_book_statuses_db.change_book_status_name
                let bank_change_book_exist = bank_change_book_filter.filter(bank_change_book => bank_change_book.change_book_status_id === change_book_status_id)

                if (bank_change_book_exist.length === 1) {
                  // exist value in db
                  bank_change_books_new.push(bank_change_book_exist[0])
                }else {
                  // new change_book_status
                  let tmp_change_book = {
                    bank_id: bank_id,
                    change_book_status_id: change_book_status_id,
                    change_book_status_name: change_book_status_name,
                    not_accepting: 0,
                    is_edit: 0,
                    status_text: 'new'
                  }
                  bank_change_books_new.push(tmp_change_book)
                }
              }
            }

            // การดัดแปลงรถ
            let bank_car_adapt_filter = bank_car_adapts.filter(car_adapt => car_adapt.bank_id === bank_id)

            if (bank_car_adapt_filter.length === 0) {
              // add new all row
              for(let i=0; i < car_adapt_statuses.length  ; i++) {
                let car_adapt_status = car_adapt_statuses[i]
                let car_adapt_status_id = car_adapt_status.car_adapt_status_id
                let car_adapt_status_name = car_adapt_status.car_adapt_status_name

                let tmp_car_adapt = {
                  bank_id: bank_id,
                  car_adapt_status_id: car_adapt_status_id,
                  car_adapt_status_name: car_adapt_status_name,
                  not_accepting: 0,
                  is_edit: 0,
                  status_text: 'new'
                }
                bank_car_adapts_new.push(tmp_car_adapt)
              }
            } else {
              // exist data
              let bank_id = bank_car_adapt_filter[0].bank_id
              for(let i=0; i < car_adapt_statuses.length  ; i++) {
                let car_adapt_status = car_adapt_statuses[i]
                let car_adapt_status_id = car_adapt_status.car_adapt_status_id
                let car_adapt_status_name = car_adapt_status.car_adapt_status_name
                let bank_car_adapt_exist = bank_car_adapt_filter.filter(bank_car_adapt => bank_car_adapt.car_adapt_status_id === car_adapt_status_id)
                if (bank_car_adapt_exist.length === 1) {
                  bank_car_adapts_new.push(bank_car_adapt_exist[0])
                } else {
                  let tmp_car_adapt = {
                    bank_id: bank_id,
                    car_adapt_status_id: car_adapt_status_id,
                    car_adapt_status_name: car_adapt_status_name,
                    not_accepting: 0,
                    is_edit: 0,
                    status_text: 'new'
                  }
                  bank_car_adapts_new.push(tmp_car_adapt)
                }
              }
            }

          })


          this.setState({
            isLoading: false,
            banks: data.banks,
            bank_change_books: bank_change_books_new,
            bank_car_adapts: bank_car_adapts_new,
            change_book_statuses: change_book_statuses,
            car_adapt_statuses: car_adapt_statuses,
            car_leasing_settings: data.car_leasing_settings,
          })
        } else {
          this.setState({banks: []})
        }
        // alert (res.data)
      }).catch(error => {
      this.setState({banks: []})
    })
  }

  onChangeSwitchChangeBook(checked,item){
    let bank_id = item.bank_id
    let not_accepting = checked ? 1 : 0
    let change_book_status_id = item.change_book_status_id
    let bank_change_books = this.state.bank_change_books.map(change_book => change_book.bank_id === bank_id && change_book.change_book_status_id === change_book_status_id ? {...change_book, not_accepting: not_accepting, is_edit: 1} : change_book  )
    this.setState({
      bank_change_books: bank_change_books
    }, () => {
      let bank_change_books_update = bank_change_books.filter(bank_change => bank_change.bank_id === bank_id)
      this.addOrUpdateBankChangeBooks(bank_change_books_update)
    })
  }

  addOrUpdateBankChangeBooks(bank_change_books_update) {
    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/bank/change_book_status', bank_change_books_update).then(res => {
        if(res.data === 'success') {
          this.loadBankDataIsLeasingAndFinanceCriteria()
        }
      }).catch(err => {
        console.log('err:', err)
      })
    })

  }

  onChangeSwitchCarAdapt(checked,item){
    let bank_id = item.bank_id
    let not_accepting = checked ? 1 : 0
    let car_adapt_status_id = item.car_adapt_status_id
    let bank_car_adapts = this.state.bank_car_adapts.map(car_adapt => car_adapt.bank_id === bank_id && car_adapt.car_adapt_status_id === car_adapt_status_id ? {...car_adapt, not_accepting: not_accepting, is_edit: 1} : car_adapt )
    this.setState({
      bank_car_adapts: bank_car_adapts
    }, () => {
      let bank_car_adapts_update = bank_car_adapts.filter(car_adapt => car_adapt.bank_id === bank_id)
      this.addOrUpdateBankCarAdapt(bank_car_adapts_update)
    })
  }

  addOrUpdateBankCarAdapt(bank_car_adapts_update) {
    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/bank/car_adapt_status', bank_car_adapts_update).then(res => {
        if(res.data === 'success') {
          this.loadBankDataIsLeasingAndFinanceCriteria()
        }
      }).catch(err => {
        console.log('err:', err)
      })
    })

  }

  onChangeCarLeasingName(bankSelect, e) {
     e.preventDefault()
     let bank_id = bankSelect.bank_id
     let car_leasing_name = e.target.value

     let banks = this.state.banks.map(bank => bank.bank_id === bank_id ? {...bank, car_leasing_name: car_leasing_name } : bank)
     this.setState({
       banks: banks
     }, () => {
        // car_leasing_name !== '' &&
        if(bankSelect.car_leasing_name !== car_leasing_name) {
          let data = {
            bank_id: bank_id,
            car_leasing_name: car_leasing_name
          }
          axios.put(APIURL + '/bank/car_leasing_name/'+bank_id, data).then(res => {

          }).catch(err => {
            console.log('err: ', err)
          })
        }
     });
  }

  render () {

    let {
      banks,
      bank_change_books,
      bank_car_adapts,
      car_leasing_settings
    } = this.state
// change_book_statuses,

    let banksTrs = banks.map((bank, index) => {
      let bank_id = bank.bank_id
      // let car_leasing_name = bank.car_leasing_name
      let bank_change_book_filter = bank_change_books.filter(change_book => change_book.bank_id === bank_id)
      let bank_car_adapt_filter = bank_car_adapts.filter(car_adapt => car_adapt.bank_id === bank_id)

      return <tr key={index}>
        <td style={{width: 28}} className="text-center" > {index+1} </td>
        <td style={{width: 100}} > {bank.bank_name} </td>
        <td style={{width: 50}} >
         <select name="car_leasing_name"
                 className="form-control"
                 value={this.state.banks[index].car_leasing_name}
                 onChange={(e) => this.onChangeCarLeasingName(bank,e)}
         >
           <option value="">เลือกชื่อย่อ</option>
          {
            car_leasing_settings.map((car_leasing_setting, index) => (
              <option key={index} value={car_leasing_setting.name_credit}>{car_leasing_setting.name_credit}</option>
            ))
          }
         </select>
        </td>
        <td className=" text-center" >
          <div className="form-inline">
          {
            bank_change_book_filter.map((bank_change_book, index) => (
              <div key={index} style={{width: 82}}>
                {bank_change_book.change_book_status_name} <br/>
                <Switch height={20} width={48} onColor={'#f00'}
                        onChange={(checked) => this.onChangeSwitchChangeBook(checked, bank_change_book)}
                        checked={bank_change_book.not_accepting === 1}
                />
              </div>
            ))
          }
          </div>
        </td>
        <td className="text-center">
          <div className="form-inline">
          {
            bank_car_adapt_filter.map((car_adapt, index) => (
              <div key={index} style={{width: 82}}>
                {car_adapt.car_adapt_status_name} <br/>
                <Switch height={20} width={48} onColor={'#f00'}
                        onChange={(checked) => this.onChangeSwitchCarAdapt(checked, car_adapt)}
                        checked={car_adapt.not_accepting === 1}
                />
              </div>
            ))
          }
          </div>
        </td>
      </tr>
    })

    return (
      <div className="row">
        <Loading isLoading={this.state.isLoading} />

        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header card-customer">
              <strong> ตั้งค่าเงื่อนไขไฟแนนซ์ </strong>
            </div>
            <div className="card-block">
              <div className="row">
                <table className="table table-striped table-responsive">
                  <thead>
                  <tr>
                    <th className="text-center" style={{width: 28}}>ลำดับ</th>
                    <th style={{width: 100}} className=" text-nowrap">ไฟแนนซ์/ลิสซิ่ง</th>
                    <th style={{width: 100}} className=" text-nowrap">ตัวย่อ</th>
                    <th style={{width: 100}} className=" text-nowrap">เงื่อนไขการเปลี่ยนเล่ม(ไม่รับจัดไฟแนนซ์)</th>
                    <th style={{width: 100}} className=" text-nowrap">เงื่อนไขการดัดแปลง (ไม่รับจัดไฟแนนซ์)</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    banksTrs
                  }
                  </tbody>
                </table>
              </div>
              *** เลือกตัวย่อให้ตรงกับชื่อธนาคาร หากไม่พบตัวย่อกรุณาติดต่อผู้ดูแลระบบ
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default FinanceCriteria
