

class StringUtil {

    addSpacePaddingRightLeft(str, fixLength ){
        // เพิ่ม 2 ข้างเท่าๆ กัน
        if(typeof str !== 'string'){
            str = String(str)
        }

        let curLen = str.length;
        let lenAll = Number(fixLength);
        let lenSpace = lenAll - curLen;

        if(str.length === 1) {
            lenSpace = lenSpace + 2
        }

        // console.log(str, ' len:', curLen)
        // console.log('lenAll:', lenAll)
        // console.log('lenSpace:', lenSpace)

        let strNew = str;
        if(lenSpace > 0) {
            let lenSpaceHaft = parseInt(String(lenSpace/2));
            if(lenSpaceHaft > 0) {
                let space=''
                for(let i=0; i < lenSpaceHaft ;i++){
                    space+=' ';
                }

                return space+strNew+space;
            }
        }
        return strNew;
    }

    addSpacePaddingFill(str, fixLength ){
        // เพิ่มหน้า 5 เ

        if (str === null) {
            str = '-';
        }

        let curLen = str.length;
        let lenAll = Number(fixLength);

        if(curLen === 0) {
            str = '-';
            curLen = 1;
        }

        let lenSpace = lenAll - curLen;

        let strNew = str;
        if(lenSpace > 0) {

                let space=''
                let spaceStart= '     ' // 5 space
                let lenSpaceEnd = lenSpace - spaceStart.length

                for(let i=0; i < lenSpaceEnd ;i++){
                    space+=' ';
                }

                return spaceStart+strNew+space;
        }
        return strNew;
    }

    getCarUploadCode() {
        const randomString = require('random-string')
        let random_code = randomString({
            length: 28,
            numeric: true,
            letters: true,
            special: false,
            exclude: ['o', '0', 'l']
        })
        return random_code
    }

}

export default new StringUtil();
