import axios from "axios";
import {APIURL} from "../config/config";

export const getLicienseStatuses = async () => {
    try{
        //let result = await axios.get(APIURL + '/liciense_status')
        return await axios.get(APIURL + '/liciense_status')
    }catch (e) {
        console.log('error: ', e)
        return null
    }
}