import React , { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import axios from 'axios';
// import { APIURL } from '../../../config/config';
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission'
import Loading from '../Loading'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import TaxDocument from './TaxDocument'
import { getTaxDocTypeById, getTaxDocTypes } from '../../services/taxDocTypeService'
import { APIURL } from '../../config/config'

class TaxDocumentTabs extends Component{
  constructor (props) {
    super(props)
    this.state = {
      menuID: [3701, 3702, 3703, 3704, 3705, 3706],
      grouppermissions: [],
      user: JSON.parse(this.props.user),
      activeTab: '1',
      isLoading: false,
      tax_doc_types: [],
      is_date_fill: 0,
    }

    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.toggle = this.toggle.bind(this);


  }

  async componentDidMount () {
    this.loadGroupPermission()
    await this.loadCompanySetting()
    await this.loadTaxDocTypes()
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    // console.log('type=', type)
    // console.log('menuID=', menuID)
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  async loadTaxDocTypes() {

    let tax_doc_types = await getTaxDocTypes()

    if (tax_doc_types !== null) {
      this.setState({
        tax_doc_types: tax_doc_types
      })
    } else {
      this.setState({
        tax_doc_types: []
      })
    }
  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      const is_date_fill = company_setting.is_date_fill;
      // // company_setting: company_setting,
      this.setState({
        is_date_fill: is_date_fill
      })
    })
  }


  render () {
    const {
      tax_doc_types
    } = this.state

    return (
      <div className="col-md-12 mb-4">
        <Loading isLoading={this.state.isLoading} />

        <Nav tabs>

          {
            checkGroupPermission(3701, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  <i className="icon-notebook"/> ภพ.30
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3702, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  <i className="icon-user"/> ภงด.3
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3703, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  <i className="icon-briefcase"/> ภงด.53
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3704, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  <i className="icon-drawer"/> ภงด.1ก
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3705, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  <i className="icon-layers"/> เงินสมทบประกันสังคม
                </NavLink>
              </NavItem>
            ) : null
          }

          {
            checkGroupPermission(3706, this.state.grouppermissions).viewed ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '6' })}
                  onClick={() => { this.toggle('6'); }}
                >
                  <i className="icon-people"/> เงินเดือน
                </NavLink>
              </NavItem>
            ) : null
          }

        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {
              this.state.activeTab === '1' && tax_doc_types.length > 0 && <TaxDocument
                tax_doc_type_id={1}
                menu_id={3701}
                grouppermissions={this.state.grouppermissions}
                tax_doc_types={this.state.tax_doc_types}
                isDateFill = {this.state.is_date_fill}
                username={this.state.user.user}
                onTaxDocumentEditSelected={this.onTaxDocumentEditSelected}
              />
            }
          </TabPane>

          <TabPane tabId="2">
            {
              this.state.activeTab === '2' &&  <TaxDocument
                tax_doc_type_id={2}
                menu_id={3702}
                grouppermissions={this.state.grouppermissions}
                tax_doc_types={this.state.tax_doc_types}
                isDateFill = {this.state.is_date_fill}
                username={this.state.user.user}
                onTaxDocumentEditSelected={this.onTaxDocumentEditSelected}
              />
            }
          </TabPane>

          <TabPane tabId="3">
            {
              this.state.activeTab === '3' && <TaxDocument
                tax_doc_type_id={3}
                menu_id={3703}
                grouppermissions={this.state.grouppermissions}
                tax_doc_types={this.state.tax_doc_types}
                isDateFill = {this.state.is_date_fill}
                username={this.state.user.user}
                onTaxDocumentEditSelected={this.onTaxDocumentEditSelected}
              />
            }
          </TabPane>

          <TabPane tabId="4">
            {
              this.state.activeTab === '4' && <TaxDocument
                tax_doc_type_id={4}
                menu_id={3704}
                grouppermissions={this.state.grouppermissions}
                tax_doc_types={this.state.tax_doc_types}
                isDateFill = {this.state.is_date_fill}
                username={this.state.user.user}
                onTaxDocumentEditSelected={this.onTaxDocumentEditSelected}
              />
            }
          </TabPane>

          <TabPane tabId="5">
            {
              this.state.activeTab === '5' && <TaxDocument
                tax_doc_type_id={5}
                menu_id={3705}
                grouppermissions={this.state.grouppermissions}
                tax_doc_types={this.state.tax_doc_types}
                isDateFill = {this.state.is_date_fill}
                username={this.state.user.user}
                onTaxDocumentEditSelected={this.onTaxDocumentEditSelected}
              />
            }
          </TabPane>

          <TabPane tabId="6">
            {
              this.state.activeTab === '6' && <TaxDocument
                tax_doc_type_id={6}
                menu_id={3706}
                grouppermissions={this.state.grouppermissions}
                tax_doc_types={this.state.tax_doc_types}
                isDateFill = {this.state.is_date_fill}
                username={this.state.user.user}
                onTaxDocumentEditSelected={this.onTaxDocumentEditSelected}
              />
            }
          </TabPane>

        </TabContent>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(TaxDocumentTabs)

