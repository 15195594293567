import React, { Component } from 'react';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput  from 'react-maskedinput';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import OccupationModal from '../OccupationModal';
import {format} from "date-fns";
import {DateUtil} from "../../../utils/dateUtil";
import { AlertError, AlertSuccess, AlertWarning } from '../../Alert/Alert'
import Swal from 'sweetalert2'
import {checkFormControls} from "../../../services/grouppermission";
import BDatePicker from "../../BDatePicker";
import { CarUtil } from '../../../utils/carUtil'
moment.locale('th');
// import DatePicker from 'react-datepicker'

export  default class CustomerForm extends Component{
    constructor(props){
        super(props);
        //this.handleOnSubmitCustomer.bind(this);
        this.state={
            lists: [],
            load_data: true,
            danger: false,
            customer_id:0,
            action: "บันทึก",
            customer: '' ,
            startDateCustomerNew1: moment().format('YYYY-MM-DD'),
            startDateCustomerDateBook: moment().format('YYYY-MM-DD'),
            province: [],
            occupation_id: 0,
            occupation_name: '',
            customer_income: 0,
            isOccupationModal: false,
            occupation: null,
            employee:[],
            form_controls:[],
            // employee_id: 0,
            // startDateCustomerNew2: moment()
            customer_birth: null,
            customer_age: '',
            customer_mobile: '',
            customer_mobile2: '',
            form_status: 'add',
            employment_types: [],
            work_experiences: [],
            salary_channels: [],
            salary_receipt_types: [],
            car_type_interests: [],
            employment_type: '',
            work_experience: '',
            salary_channel: '',
            salary_receipt_type: '',
            date_oneyear_employment: null,
            customer_car_want: '',
            car_brands: [],
            car_models: [],
            car_type_interest_id: '0',
            car_brand_id: '',
            car_model_id: '',
            installment_payment: '',
        }

        this.handleDateCustomerNew1 = this.handleDateCustomerNew1.bind(this);
        this.convertBBtoCC = this.convertBBtoCC.bind(this);
        this.onMaskedDateCustomerNew1 = this.onMaskedDateCustomerNew1.bind(this);
        this.loadProvinceData = this.loadProvinceData.bind(this);
        this.onSelectOccupation = this.onSelectOccupation.bind(this);
        this.loadEmployeeData = this.loadEmployeeData.bind(this);
        this.loadCustomerOptions = this.loadCustomerOptions.bind(this);
        this.onMaskedDateCustomerDateBook = this.onMaskedDateCustomerDateBook.bind(this);
        this.handleDateCustomerDateBook = this.handleDateCustomerDateBook.bind(this);
        this.handleOnSubmitCustomer = this.handleOnSubmitCustomer.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChangeCustomerCardId = this.handleOnChangeCustomerCardId.bind(this);
        this.checkCustomerByCardId = this.checkCustomerByCardId.bind(this);
        this.loadFormControls = this.loadFormControls.bind(this);
        this.loadCarBrandData = this.loadCarBrandData.bind(this);
        this.handleCarBrandChange = this.handleCarBrandChange.bind(this);
        this.loadCarModel = this.loadCarModel.bind(this);
        // this.handleDateCustomerNew2 = this.handleDateCustomerNew2.bind(this);
    }

    loadCarBrandData () {
        axios.get(APIURL + '/car_brand/')
          .then(res => {
              this.setState({car_brands: res.data})
              //alert (res.data)
          }).catch(error => {
            this.setState({car_brands: []})
        })
    }

    handleCarBrandChange (event) {
        let car_brand_id = event.target.value
        // var car_brand_name = event.target.options[event.target.selectedIndex].text
        this.loadCarModel(car_brand_id);
    }

    loadCarModel(car_brand_id){
        axios.get(APIURL + '/car_brand_model/' + car_brand_id)
          .then(res => {
              this.setState({
                  car_brand_id: car_brand_id,
                  car_models: res.data,
              })
          }).catch(error => {
            this.setState({car_models: []})
        })
    }

    loadFormControls() {
        let tableName = "customer";
        axios.get(APIURL + '/form_controls/'+tableName)
            .then(res => {
                this.setState({form_controls: res.data});
            }).catch(error => {
            this.setState({form_controls: []})
        })
    }

    handleOnChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            [name]: value
        })
    }

    componentDidMount () {
        this.loadFormControls()
        this.loadProvinceData()
        this.loadCustomerOptions()
        this.loadEmployeeData()
        this.loadCarBrandData()
    }

    handleOnSubmitCustomer(event){
        event.preventDefault()
        //alert(event.target.customer_name.value);
        let username = this.props.username
        let that = this ;

        let customer_name = event.target.customer_name.value
        let customer_lastname = event.target.customer_lastname.value
        let customer_card_id = event.target.customer_card_id.value
        let customer_birth_text = event.target.customer_birth_text.value
        // let customer_birth = customer_birth_text === '' ? '' : this.state.startDateCustomerNew1
        let customer_birth = this.state.customer_birth

        // let customer_age = event.target.customer_age.value
        // let customer_mobile = event.target.customer_mobile.value

        let customer_age = this.state.customer_age
        let customer_mobile = this.state.customer_mobile
        let customer_mobile2 = this.state.customer_mobile2

        let customer_address = event.target.customer_address.value
        //
        let occupation_id = event.target.occupation_id.value
        let customer_income = event.target.customer_income.value
        let province_id = event.target.province_id.value
        //
        let guarantor_name = event.target.guarantor_name.value
        let guarantor_address = event.target.guarantor_address.value
        let guarantor_mobile = event.target.guarantor_mobile.value

        let employee_id = event.target.employee_id.value

        let customer_date_book_text = event.target.customer_date_book_text.value
        // let customer_date_book = this.state.startDateCustomerDateBook
        let customer_date_book = customer_date_book_text === '' ? '' : this.state.startDateCustomerDateBook
        let customer_note = event.target.customer_note.value
        let customer_line = event.target.customer_line.value
        let customer_facebook = event.target.customer_facebook.value
        let customer_car_want = event.target.customer_car_want.value

        let car_type_interest_id = this.state.car_type_interest_id
        let car_brand_id = this.state.car_brand_id
        let car_model_id = this.state.car_model_id
        let installment_payment = this.state.installment_payment

        let customer_find_car_data = {
            car_type_interest_id,
            car_brand_id,
            car_model_id,
            installment_payment
        }

        let customer_find_car = CarUtil.isCustomerFindCarFill(customer_find_car_data) ? customer_find_car_data : null

        if (customer_find_car !== null) {
            customer_find_car = {...customer_find_car, username: username}
        }

        if(customer_name===''){
            AlertWarning('กรุณากรอกชื่อลูกค้า');
            return ;
        }

        let employment_type = this.state.employment_type
        let work_experience = this.state.work_experience
        let salary_channel = this.state.salary_channel
        let salary_receipt_type = this.state.salary_receipt_type
        let date_oneyear_employment = this.state.date_oneyear_employment

        event.target.customer_name.value = ""
        event.target.customer_lastname.value = ""
        event.target.customer_card_id.value = ""
        //event.target.customer_birth.value = ""
        event.target.customer_birth_text.value = ""
        event.target.customer_age.value = ""
        event.target.customer_mobile.value = ""
        event.target.customer_address.value = ""
        //
        event.target.occupation_id.value = ""
        event.target.customer_income.value = ""
        event.target.province_id.value = ""
        //
        event.target.guarantor_name.value = ""
        event.target.guarantor_address.value = ""
        event.target.guarantor_mobile.value = ""

        event.target.employee_id.value = ""
        event.target.customer_date_book_text.value = ""
        event.target.customer_note.value = ""

        event.target.customer_line.value = ""
        event.target.customer_facebook.value = ""
        event.target.customer_car_want.value = ""


        let data = {
            customer_name: customer_name,
            customer_lastname: customer_lastname,
            customer_card_id : customer_card_id,
            customer_birth: customer_birth,
            customer_age : customer_age,
            customer_mobile: customer_mobile,
            customer_mobile2: customer_mobile2,
            customer_address : customer_address,
            username: username,
            guarantor_name: guarantor_name,
            guarantor_address: guarantor_address,
            guarantor_mobile: guarantor_mobile,
            occupation_id: occupation_id,
            customer_income: customer_income,
            province_id: province_id,
            employee_id: employee_id,
            customer_date_book: customer_date_book,
            customer_note: customer_note,
            customer_line: customer_line,
            customer_facebook: customer_facebook,
            employment_type,
            work_experience,
            salary_channel,
            salary_receipt_type,
            date_oneyear_employment,
            customer_car_want,
            customer_find_car: customer_find_car
        }

        // console.log(data)
        // return

        axios.post(APIURL + '/customer/', data)
            .then(function (response) {
                AlertSuccess('บันทึกข้อมูลลูกค้าเรียบร้อย');
                //alert(response.data)
                //that.props.history.push('/customer/list');
                let customer = response.data

                if (customer === null) {
                    let customer_label = data.customer_name+' '+data.customer_lastname
                    let card_id = data.customer_card_id
                    let title = 'ลูกค้า '+customer_label + ' เลขบัตร '+card_id+' ซ้ำ ไม่สามารถเพิ่มข้อมูลได้';
                    AlertError(title);
                    return
                }

                that.props.onToggle(customer);
                //that.setState({ customer: '', customer_id: 0, action: "บันทึก"})
            })
            .catch(function (error) {
                //alert("error:"+error)
            })
    }

    handleDateCustomerNew1(date) {

        let customer_birth = format(new Date(date),'yyyy-MM-dd');
        let years = DateUtil.calculateAge(customer_birth);

        // startDateCustomerNew1: moment(date).format('YYYY-MM-DD')
        this.setState({
            startDateCustomerNew1: format(new Date(date),'yyyy-MM-dd'),
            customer_birth: customer_birth,
            customer_age: years
        });
    }

    handleDateCustomerDateBook(date) {
        this.setState({
            startDateCustomerDateBook: moment(date).format('YYYY-MM-DD')
        });
    }

    onMaskedDateCustomerNew1 (e){
        let buddhist_date = e.target.value ;
        //alert('date='+date);
        this.setState({
            customer_birth_text: buddhist_date
        })

        if (buddhist_date.length===0) {
            // customer_birth
            this.setState({
                customer_birth: null
            })
        }

        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.handleDateCustomerNew1(cc_date)
        }
    }

    onMaskedDateCustomerDateBook(e){
        let buddhist_date = e.target.value ;
        //alert('date='+date);
        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.handleDateCustomerDateBook(cc_date)
        }
    }

    convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
        let date_arr=buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = date_arr[2]-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    loadProvinceData () {
        axios.get(APIURL + '/province/')
          .then(res => {
              this.setState({province: res.data})
          }).catch(error => {
            this.setState({province: []})
        })
    }

    loadCustomerOptions () {
        axios.get(APIURL + '/customer/option/data')
            .then(res => {

                 let employment_types = res.data.employment_types
                 let work_experiences = res.data.work_experiences
                 let salary_channels = res.data.salary_channels
                 let salary_receipt_types = res.data.salary_receipt_types
                 let car_type_interests = res.data.car_type_interests

                this.setState({
                    employment_types: employment_types,
                    work_experiences: work_experiences,
                    salary_channels: salary_channels,
                    salary_receipt_types: salary_receipt_types,
                    car_type_interests: car_type_interests,
                })
            }).catch(error => {
                console.log('error:', error)
            // this.setState({employee: []})
        })
    }

    loadEmployeeData () {
        axios.get(APIURL + '/employee/')
            .then(res => {
                this.setState({employee: res.data})
            }).catch(error => {
            this.setState({employee: []})
        })
    }

    onSelectOccupation(occupation) {
        this.setState({
            occupation: occupation,
            occupation_id: occupation.occupation_id,
            occupation_name: occupation.occupation_name,
            isOccupationModal: false
        })
    }

    handleOnChangeCustomerCardId(e) {
        let value = e.target.value;

        if(typeof String.prototype.replaceAll === "undefined") {
            String.prototype.replaceAll = function(match, replace) {
                return this.replace(new RegExp(match, 'g'), () => replace);
            }
        }

        let cardNumberEmpty = value.replaceAll(' ', '')
        let cardNumberUnderScore = cardNumberEmpty.replaceAll('_', '')
        let cardNumber = cardNumberUnderScore.replaceAll('-', '')
        // console.log('value=', value)
        // console.log('cardNumber=', cardNumber)
        this.setState({
            customer_card_id: value
        }, () => {
            if (cardNumber.length === 13) {
                // console.log('check card id repeat: ', cardNumber)
                this.checkCustomerByCardId(cardNumber)
            }
        })
    }

    checkCustomerByCardId(card_id) {
        let uri =  APIURL + '/customer/check_card_id/'+card_id;
        axios.get(uri)
          .then(res => {
              let data = res.data

              if(data !== null) {
                  let customer = data
                  let customer_label = customer.customer_name+' '+customer.customer_lastname
                  Swal.fire({
                      title: 'ลูกค้า '+customer_label + ' เลขบัตร '+card_id+' ซ้ำ ไม่สามารถเพิ่มรายการได้',
                      icon: 'warning',
                      showConfirmButton: true,
                      showCancelButton: true,
                  }).then((result) => {
                      if (result.value) {
                          this.props.onToggle(customer);
                      } else {
                          this.props.onToggle(customer);
                      }
                  })
              }
          }).catch(error => {

        })
    }

    render(){
        // const { history } = this.props
        const {
            form_controls,
            employment_types ,
            work_experiences ,
            salary_channels ,
            salary_receipt_types,
            car_type_interests,
            car_brands,
            car_models
        } = this.state

        let province_list = this.state.province.map((province, index) => {
            return (
              <option key={index+1} value={province.province_id}>{province.province_name}</option>
            )
        })

        let employee_list = this.state.employee.map((employee, index) => {
            return (
                <option key={index+1} value={employee.employee_id}>{employee.employee_name}</option>
            )
        })

        let employment_type_list = employment_types.map((obj, index) => {
            return (
                <option key={index+1} value={obj.employment_type}>{obj.employment_type}</option>
            )
        })

        let work_experience_list = work_experiences.map((obj, index) => {
            return (
                <option key={index+1} value={obj.work_experience}>{obj.work_experience}</option>
            )
        })

        let salary_channel_list = salary_channels.map((obj, index) => {
            return (
                <option key={index+1} value={obj.salary_channel}>{obj.salary_channel}</option>
            )
        })

        let salary_receipt_type_list = salary_receipt_types.map((obj, index) => {
            return (
                <option key={index+1} value={obj.salary_receipt_type}>{obj.salary_receipt_type}</option>
            )
        })

        let car_type_interests_list = car_type_interests.map((obj, index) => {
            return (
              <option key={index+1} value={obj.car_type_interest_id}>{obj.car_type_interest_name}</option>
            )
        })

        let car_brand_list = car_brands.map((car_brand, index) => {
            return (
              <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
            )
        })

        let car_brand_model = car_models.map((car_model, index) => {
            return (
              <option key={index} value={car_model.car_model_id}>{car_model.car_model_name}</option>
            )
        })

        return(

            <div className="card card-accent-primary">

                <OccupationModal
                  isModal={this.state.isOccupationModal}
                  onSelectOccupation={this.onSelectOccupation}
                  closeModal={() => {
                      this.setState({
                          isOccupationModal: false,
                          occupation: null
                      })
                  }}
                />

                <form action="" method="post" onSubmit={this.handleOnSubmitCustomer}>
                    <div className="card-header">
                        <strong className="text-title">ข้อมูลลูกค้าใหม่</strong>
                    </div>

                <div className="card-block">
                    <div className="row">
                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_name">ชื่อ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_name"
                                   placeholder=""
                            />
                        </div>
                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_lastname">นามสกุล</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_lastname"
                                   placeholder=""
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="customer_card_id_old-bk"
                                   maxLength="13"
                                   placeholder=""
                            />

                            <MaskedInput
                              mask="1-1111-11111-11-1"
                              id="customer_card_id"
                              name="customer_card_id"
                              maxLength="20"
                              placeholder=""
                              className="form-control"
                              onChange={this.handleOnChangeCustomerCardId}
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_birth_text">วันเดือนปีเกิด (พ.ศ.)</label>
                            <MaskedInput
                              mask="11-11-1111"
                              name="customer_birth_text"
                              placeholder="dd-mm-yyyy"
                              value={this.state.customer_birth_text}
                              onChange={this.onMaskedDateCustomerNew1}
                              className="form-control"
                            />
                        </div>


                        <div className="form-group col-sm-2">
                            <label htmlFor="customer_age">อายุ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_age"
                                   name="customer_age"
                                   placeholder=""
                                   value={this.state.customer_age}
                                   onChange={this.handleOnChange}
                            />
                        </div>

                        <div className="form-group col-sm-2">
                            <label htmlFor="customer_mobile">เบอร์โทรศัพท์(1)</label>

                            <MaskedInput
                              mask="111-111-1111"
                              id="customer_mobile"
                              name="customer_mobile"
                              value={this.state.customer_mobile}
                              placeholder=""
                              className="form-control"
                              onChange={this.handleOnChange}
                            />
                        </div>

                        <div className="form-group col-sm-2">
                            <label htmlFor="customer_mobile">เบอร์โทรศัพท์(2)</label>
                            <MaskedInput
                                mask="111-111-1111"
                                id="customer_mobile2"
                                name="customer_mobile2"
                                value={this.state.customer_mobile2}
                                placeholder=""
                                className="form-control"
                                onChange={this.handleOnChange}
                            />
                        </div>

                        <div className="form-group col-sm-6">
                            <label htmlFor="customer_address">ที่อยู่ปัจจุบัน</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_address"
                                   placeholder=""
                            />
                        </div>


                        <div className="form-group col-sm-3">
                            <label htmlFor="province">จังหวัด</label>
                            <select className="form-control" name="province_id">
                                <option key={0} value={0}>ไม่ระบุ</option>
                                {province_list}
                            </select>
                        </div>


                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_line">Line</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_line"
                                   placeholder=""
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_facebook">Facebook</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_facebook"
                                   placeholder=""
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="employee">เซลล์ผู้ดูแล</label>
                            <select className="form-control" name="employee_id">
                                <option key={0} value={0}>ไม่ระบุ</option>
                                {employee_list}
                            </select>
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="receipt_date">อาชีพ</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="occupation_id"
                                   name="occupation_id"
                                   placeholder=""
                                   value={this.state.occupation_id}
                                   disabled={false}
                                   onChange={() => {}}
                            />
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <input type="text"
                                               className="form-control"
                                               placeholder=""
                                               value={this.state.occupation_id !== 0 ? this.state.occupation_name : '' }
                                               disabled={true}
                                        />
                                        <span className="input-group-btn">
                                            <button className="btn btn-secondary"
                                                    name="agent_find"
                                                    type="button"
                                                    style={styles.buttonHeight}
                                                    onClick={() => {
                                                        this.setState({
                                                            isOccupationModal: true
                                                        })
                                                    }}
                                            >
                                                <i className="icon-magnifier"/>
                                            </button>

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="employee">ประเภทการจ้าง</label>
                            <select className="form-control"
                                    name="employment_type"
                                    value={this.state.employment_type}
                                    onChange={this.handleOnChange}
                            >
                                <option key={0} value={''}>ไม่ระบุ</option>
                                {employment_type_list}
                            </select>
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="employee">อายุงาน</label>
                            <select className="form-control"
                                    name="work_experience"
                                    value={this.state.work_experience}
                                    onChange={this.handleOnChange}
                            >
                                <option key={0} value={''}>ไม่ระบุ</option>
                                {work_experience_list}
                            </select>
                        </div>

                        <div className="form-group col-sm-3">
                            <BDatePicker
                                isDateFill={0}
                                name={'date_oneyear_employment'}
                                value={this.state.date_oneyear_employment}
                                label={'วันที่อายุงานครบ 1 ปี'}
                                onUpdateDate={(buddhist_date, christ_date) => {
                                    this.setState({
                                        date_oneyear_employment: christ_date
                                    })
                                }}
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="employee">ช่องทางรับเงินเดือน</label>
                            <select className="form-control"
                                    name="salary_channel"
                                    value={this.state.salary_channel}
                                    onChange={this.handleOnChange}
                            >
                                <option key={0} value={''}>ไม่ระบุ</option>
                                {salary_channel_list}
                            </select>
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="employee">ประเภทใบรับเงินเดือน</label>
                            <select className="form-control"
                                    name="salary_receipt_type"
                                    value={this.state.salary_receipt_type}
                                    onChange={this.handleOnChange}
                            >
                                <option key={0} value={''}>ไม่ระบุ</option>
                                {salary_receipt_type_list}
                            </select>
                        </div>


                        <div className="form-group col-sm-3">
                            <label htmlFor="cost">รายได้/เงินเดือน</label>
                            <input type="text"
                                   className="form-control hidden "
                                   name="customer_income"
                                   placeholder=""
                                   ref="customer_income"
                                   value={this.state.customer_income}
                                   onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="รายได้/เงินเดือน"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                  const {value} = values
                                  this.setState({customer_income: value})
                              }}
                            />
                        </div>


                        <div
                             className={checkFormControls('customer_date_book', form_controls) ? "form-group col-sm-3" : "form-group col-sm-3 hidden" }
                        >
                            <label htmlFor="customer_date_book">วันที่จอง ว/ด/ป (พ.ศ.)</label>
                            <MaskedInput
                                mask="11-11-1111"
                                name="customer_date_book_text"
                                placeholder="dd-mm-yyyy"
                                onChange={this.onMaskedDateCustomerDateBook}
                                className="form-control"
                            />
                        </div>

                        <div className="form-group col-sm-12">
                            <label htmlFor="customer_note">หมายเหตุ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_note"
                                   placeholder=""
                            />
                        </div>

                        <div className="form-group col-sm-6 hidden">
                            <label htmlFor="customer_car_want">รถที่ลูกค้าต้องการ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_car_want"
                                   name="customer_car_want"
                                   placeholder=""
                                   maxLength={200}
                            />
                        </div>

                    </div>

                      <div className="row">
                        <div className="col-sm-12">
                            <hr />
                        </div>
                        <div className="col-sm-12">
                            <strong>ข้อมูลผู้ค้ำประกัน/บุคคลอ้างอิง</strong>
                       </div>

                       <div className="row col-sm-12" style={{marginTop: 20}}>
                           <div className="form-group col-sm-3" >
                               <label htmlFor="guarantor_name">ชื่อ-นามสกุลผู้ค้ำ</label>
                               <input type="text"
                                      className="form-control"
                                      id="guarantor_name"
                                      placeholder=""
                               />
                           </div>

                           <div className="form-group col-sm-3">
                               <label htmlFor="guarantor_mobile">เบอร์โทรผู้ค้ำ</label>
                               <input type="text"
                                      className="form-control hidden"
                                      id="guarantor_mobile"
                                      placeholder=""
                               />

                               <MaskedInput
                                 mask="111-111-1111"
                                 id="guarantor_mobile"
                                 name="guarantor_mobile"
                                 placeholder=""
                                 className="form-control"
                               />
                           </div>

                           <div className="form-group col-sm-6">
                               <label htmlFor="guarantor_address">ที่อยู่ผู้ค้ำ</label>
                               <input type="text"
                                      className="form-control"
                                      id="guarantor_address"
                                      placeholder=""
                               />
                           </div>
                       </div>

                      </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <hr />
                        </div>
                        <div className="col-sm-12">
                            <strong>ข้อมูลรถที่ลูกค้าต้องการ</strong>
                        </div>

                        <div className="row col-sm-12" style={{marginTop: 20}}>
                            <div className="form-group col-sm-3">
                                <label htmlFor="car_type_interest_id">ประเภทรถที่สนใจ</label>
                                <select className="form-control"
                                        name="car_type_interest_id"
                                        value={this.state.car_type_interest_id}
                                        onChange={this.handleOnChange}
                                >
                                    {car_type_interests_list}
                                </select>
                            </div>

                            <div className="form-group col-3">
                                <label htmlFor="car_brand_id">ยี่ห้อ</label>
                                <select className="form-control"
                                        id="car_brand_id"
                                        name="car_brand_id"
                                        onChange={this.handleCarBrandChange}
                                        value={this.state.car_brand_id || ''}
                                >
                                    <option value="">เลือกยี่ห้อรถ</option>
                                    {car_brand_list}
                                </select>
                            </div>

                            <div className="form-group col-3">
                                <label htmlFor="car_model_id">รุ่นรถ</label>
                                <select className="form-control"
                                        id="car_model_id"
                                        name="car_model_id"
                                        onChange={(e)=> {
                                            this.setState({
                                                car_model_id: e.target.value
                                            })
                                        }}
                                        value={this.state.car_model_id || ''}
                                >
                                    <option value="">เลือกรุ่นรถ</option>
                                    {car_brand_model}
                                </select>
                            </div>

                            <div className="form-group col-sm-3">
                                <label htmlFor="cost">ยอดผ่อนต่อเดือน</label>

                                <NumberFormat
                                  className="form-control"
                                  thousandSeparator={true}
                                  prefix={'฿'}
                                  placeholder="ยอดผ่อนต่อเดือน"
                                  min={0}
                                  max={1000000000}
                                  step={1000}
                                  size={10}
                                  allowNegative={false}
                                  value={this.state.installment_payment || ''}
                                  onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({installment_payment: value})
                                  }}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12 text-center">
                        <div className="card-block">
                            <button type="submit" className="btn btn-success" style={styles.buttonHeight}>
                                <i className="icon-check"/> &nbsp;บันทึกข้อมูลลูกค้า</button>
                        </div>
                    </div>
                </div>
            </form>
            </div>

        )
    }
}

// marginTop: '-0.35rem'
const styles = {
    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: 0
    },
}
