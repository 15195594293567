import axios from "axios";
import {APIURL} from "../config/config";

export const getTaxDocTypes = async () => {
  // company_setting_id
  try{
    let result = await axios.get(APIURL + '/tax_doc_type')
    return result.data
  }catch (e) {
    console.log('error: ', e)
    return null
  }
}

export const getTaxDocTypeById = async (tax_doc_type_id) => {
  // company_setting_id
  try{
    let result = await axios.get(APIURL + '/tax_doc_type/'+tax_doc_type_id)
    return result.data
  }catch (e) {
    console.log('error: ', e)
    return null
  }
}
