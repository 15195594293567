import React, { Component } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import CarExpenseForm from '../Cars/CarExpenseForm'
import BDatePicker from '../BDatePicker'
import { format } from 'date-fns'
import NumberFormat from 'react-number-format'
import { MAX_FILE_SIZE, MAX_FILE_SIZE_LIMIT, MB_SIZE } from '../../config/config'
import { AlertError } from '../Alert/Alert'

class TaxDocumentForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      tax_document_date: format(new Date(), 'yyyy-MM-dd'),
      tax_amount: 0,
      tax_document_id: 0,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount () {

    let tax_document_selected = this.props.tax_document_selected
    if (tax_document_selected !== null) {
      // set old value for edit
      let tax_document_date = format(new Date(tax_document_selected.tax_document_date), 'yyyy-MM-dd')
      console.log('---tax_document_selected---')
      console.log(tax_document_selected)
      this.refs.tax_document_detail.value = tax_document_selected.tax_document_detail
      this.setState({
        tax_document_id: tax_document_selected.tax_document_id,
        tax_document_date: tax_document_selected.tax_document_date,
        tax_amount: tax_document_selected.tax_amount,
      })
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }


  render () {

    let width = this.state.width
    console.log('width=', width)
    let tax_doc_type = this.props.tax_doc_type
    let form_status = this.props.form_status
    let isDateFill = this.props.isDateFill
    let title = form_status === 'add' ? 'เพิ่มเอกสาร '+tax_doc_type.tax_doc_type_name :  'แก้ไขเอกสาร '+tax_doc_type.tax_doc_type_name

    return <>
      <Modal isOpen={this.props.is_modal}
             toggle={this.props.closeModal}
             className={'modal-lg ' + this.props.className}
             style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
        <ModalBody>
          <div className={form_status === 'add'? "card card-accent-primary" : "card card-accent-warning" } >
            <form action=""
                  method="post"
                  onSubmit={this.props.onSubmitForm}
                  encType="multipart/form-data"
            >
              <div className="card-header">
                <strong className="text-title">{title}</strong>
              </div>
              <div className="card-block">
                <div className="row">

                  <input
                    name="tax_document_id"
                    value={this.state.tax_document_id}
                    onChange={() => {}}
                    className="hidden"
                  />

                  <div className="form-group col-sm-6">
                    <BDatePicker
                      isDateFill={this.props.isDateFill}
                      name={'tax_document_date'}
                      value={this.state.tax_document_date}
                      label={'วันที่ออกเอกสาร '}
                      onUpdateDate={(buddhist_date, christ_date) => {
                        this.setState({
                          tax_document_date: christ_date
                        })
                      }}
                    />
                  </div>
                  <div className="form-group col-sm-6">
                    <label htmlFor="tax_amount">ยอดค่าใช้จ่ายตามเอกสาร&nbsp;</label>
                    <input
                      name="tax_amount"
                      value={this.state.tax_amount}
                      onChange={() => {}}
                      className="hidden"
                    />
                    <NumberFormat
                      className="form-control"
                      style={{marginTop: -3}}
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ยอดค่าใช้จ่ายตามเอกสาร"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.tax_amount}
                      onValueChange = {(values) => {
                        const {value} = values
                        this.setState({tax_amount: value})
                      }}
                    />
                  </div>
                  </div>


                <div className="row">
                  <div className="form-group col-sm-12">

                    <label htmlFor="tax_document_detail">รายละเอียดเอกสาร</label>
                    <input type="text"
                           className="form-control"
                           ref="tax_document_detail"
                           name="tax_document_detail"
                           placeholder=""
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="tax_doc_file">เอกสารแนบ </label>
                    <input type="file"
                           id="tax_doc_file"
                           name="tax_doc_file"
                           accept="image/*, application/pdf"
                           className="form-control"
                           onChange={(e)=>{
                             e.preventDefault();
                             if (e.target.files && e.target.files[0]) {
                               let files = e.target.files;
                               let selectedFile = files[0];
                               let fileName = selectedFile.name;
                               let fileSize = selectedFile.size;

                               if(fileSize > MAX_FILE_SIZE) {
                                 const fileMB = Math.round((fileSize / MB_SIZE));
                                 AlertError('ไฟล์ '+fileName+' มีขนาด '+fileMB+ ' Mb มากกว่า '+MAX_FILE_SIZE_LIMIT+' Mb ไม่สามารถอัพโหลดได้')
                                 return
                               }
                             }
                             // const { target } = e
                             // if(target.value.length > 0){
                             //   this.setState({
                             //     w_expense_image_file: false
                             //   });
                             // } else {
                             //   //target.reset();
                             //   this.setState({
                             //     w_expense_image_file: true
                             //   });
                             // }
                           }}
                    />
                  </div>
                </div>

                </div>


              <div className="card-footer text-right">
                <button type="reset"
                        onClick={this.props.closeModal}
                        className="btn btn-sm btn-danger mr-2">
                  <i className="fa fa-refresh"/> ยกเลิก
                </button>

                  {form_status === 'add' ?
                  <>
                    <button type="submit"
                            className="btn btn-sm btn-primary">
                      <i className="fa fa-save"/> บันทึก
                    </button>
                  </> :
                  <>
                    <button type="submit"
                            className="btn btn-sm btn-warning">
                      <i className="fa fa-pencil"/> แก้ไข
                    </button>
                  </> }

              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  }
}


export default TaxDocumentForm
