import React, { Component } from 'react'
import { connect } from 'react-redux'
// import MaskedInput  from 'react-maskedinput'
import DatePicker from 'react-datepicker'
import moment from 'moment/moment'
import { APIURL } from '../../../config/config'
import axios from 'axios/index'
import MaskedInput from "react-maskedinput";
moment.locale('th')

class CustomerFindCarAdd extends Component {
  constructor (props) {
    super(props);
    this.state = {
      startDateCustomerNew1: null,
      car_brands: [],
      car_models: [],
      car_years: [],
      car_gears: [],
      car_colors: [],
      tmp_car_brand: '',
      tmp_car_model: '',
      user: {},
      customer: {},
      customer_birth_mask: Object.keys(this.props.customer).length === 0? '' : this.props.customer.customer_birth===null? '' : this.convertCCtoBB(moment(this.props.customer.customer_birth).format("YYYY-MM-DD")),
      customer_birth:      Object.keys(this.props.customer).length === 0? '' : this.props.customer.customer_birth===null? '' : moment(this.props.customer.customer_birth).format("YYYY-MM-DD"),
    }
    this.handleDateCustomerNew1 = this.handleDateCustomerNew1.bind(this);
    this.convertBBtoCC = this.convertBBtoCC.bind(this);
    this.onMaskedDateCustomerNew1 = this.onMaskedDateCustomerNew1.bind(this);
    //
    this.loadCarBrandData = this.loadCarBrandData.bind(this);
    this.loadCarYearData = this.loadCarYearData.bind(this);
    this.loadCarGearData = this.loadCarGearData.bind(this);
    this.loadCarColorData = this.loadCarColorData.bind(this);
    //
    this.handleCarBrandChange = this.handleCarBrandChange.bind(this);
    this.handleOnSubmitCustomerFindCar = this.handleOnSubmitCustomerFindCar.bind(this);
    this.onInputChangeCustomer = this.onInputChangeCustomer.bind(this);
    this.loadCarModel = this.loadCarModel.bind(this);
    this.handleCarYearChange = this.handleCarYearChange.bind(this);
    this.handleCarModelChange = this.handleCarModelChange.bind(this);
    this.handleContactDate = this.handleContactDate.bind(this);
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  componentDidMount() {
    this.loadCarBrandData();
    this.loadCarGearData();
    this.loadCarColorData();
    this.loadCarYearData();
    this.setState({
      customer: this.props.customer
    })
    // console.log(this.props.customer)
    if(Object.keys(this.props.customer).length !== 0){
      this.loadCarModel(this.props.customer.car_brand_id)
    }
  }

  loadCarBrandData () {
    axios.get(APIURL + '/car_brand/')
      .then(res => {
        this.setState({car_brands: res.data})
        //alert (res.data)
      }).catch(error => {
      this.setState({car_brands: []})
    })
  }

  loadCarYearData () {
    axios.get(APIURL + '/car_year/')
      .then(res => {
        this.setState({car_years: res.data})
        //alert (res.data)
      }).catch(error => {
      this.setState({car_years: []})
    })
  }

  loadCarGearData () {
    axios.get(APIURL + '/car_gear/')
      .then(res => {
        this.setState({car_gears: res.data})
      }).catch(error => {
      this.setState({car_gears: []})
    })
  }

  loadCarColorData () {
    axios.get(APIURL + '/car_color/')
      .then(res => {
        this.setState({car_colors: res.data})
      }).catch(error => {
      this.setState({car_colors: []})
    })
  }

  handleCarBrandChange (event) {
    let car_brand_id = event.target.value
    // var car_brand_name = event.target.options[event.target.selectedIndex].text

    this.loadCarModel(car_brand_id);
  }

  loadCarModel(car_brand_id){
    axios.get(APIURL + '/car_brand_model/' + car_brand_id)
      .then(res => {
        this.setState({
          car_models: res.data,
          customer: {...this.state.customer, car_brand_id: car_brand_id}
        },() => {
          if(this.props.actionType === 'บันทึก') {
            this.refs.car_model_id.value = ''
          }else if(this.props.actionType === 'แก้ไข'){

          }
        })
      }).catch(error => {
      this.setState({car_models: []})
    })
  }

  handleCarModelChange(event) {
    var car_model_id = event.target.value
    this.setState({
      customer: {...this.state.customer, car_model_id: car_model_id}
    });
  }

  handleDateCustomerNew1(date) {
    this.setState({
      startDateCustomerNew1: moment(date).format('YYYY-MM-DD'),
      customer_birth : date
    });
  }

  onMaskedDateCustomerNew1 (e){
    let buddhist_date = e.target.value ;
    //alert('date='+date);
    if(buddhist_date.length===10){
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateCustomerNew1(cc_date)
    }
    if(this.state.startDateCustomerNew1 !== '' && buddhist_date===''){
      this.setState({customer_birth: '', startDateCustomerNew1: ''});
    }
  }

  convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
    let date_arr=buddhist_date.split('-');
    let dd = date_arr[0];
    let mm = date_arr[1];
    let yyyy = date_arr[2]-543;
    let cc_date= yyyy+'-'+mm+'-'+dd ;
    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return cc_date ;
    }
    return moment() ;
  }

  handleOnSubmitCustomerFindCar(event) {
    event.preventDefault();
    let customer_name = event.target.customer_name.value
    let customer_lastname = event.target.customer_lastname.value
    let customer_card_id = event.target.customer_card_id.value
    let customer_birth = this.state.customer_birth
    let customer_age = event.target.customer_age.value
    let customer_mobile = event.target.customer_mobile.value
    let customer_address = event.target.customer_address.value
    //
    let car_brand_id = event.target.car_brand_id.value;
    let car_model_id = event.target.car_model_id.value;
    let car_year_start = event.target.car_year_start.value;
    let car_year_final = event.target.car_year_final.value;
    //
    let source_customer = event.target.source_customer.value;
    let contact_date = event.target.contact_date.value;
    let car_gear_id = event.target.car_gear_id.value;
    let car_color_id = event.target.car_color_id.value;

    if(customer_name === '') {
      alert('กรุณากรอกชื่อลูกค้า');
      return;
    }

    if(car_brand_id === '') {
      alert('กรุณาเลือกยี่ห้อรถ');
      return;
    }
    if(car_model_id === '') {
      alert('กรุณาเลือกรุ่นรถ');
      return;
    }

    var data = {
      customer_name: customer_name,
      customer_lastname: customer_lastname,
      customer_card_id : customer_card_id,
      customer_birth: customer_birth,
      customer_age : customer_age,
      customer_mobile: customer_mobile,
      customer_address : customer_address,
      car_brand_id: car_brand_id,
      car_model_id: car_model_id,
      car_year_start: car_year_start,
      car_year_final: car_year_final,
      username: this.state.user.user,
      actionType: this.props.actionType,
      //
      source_customer: source_customer,
      contact_date: contact_date,
      car_gear_id: car_gear_id,
      car_color_id: car_color_id
    }

    if(this.props.actionType==='แก้ไข') {
      data = {...data, customer_id: this.props.customer.customer_id, customer_find_car_id: this.props.customer.customer_find_car_id}
    }
    // console.log(data); return;
    this.props.onSubmit(data);
  }

  onInputChangeCustomer(event) {
    event.preventDefault()
    var name = event.target.value;
   // if(this.props.actionType==='แก้ไข') {
     if (event.target.id === 'customer_name') {
       this.setState({customer: {...this.state.customer, customer_name: name}});
     } else if (event.target.id === 'customer_lastname') {
       this.setState({customer: {...this.state.customer, customer_lastname: name}});
     } else if (event.target.id === 'customer_card_id') {
       this.setState({customer: {...this.state.customer, customer_card_id: name}});
     } else if (event.target.id === 'customer_address') {
       this.setState({customer: {...this.state.customer, customer_address: name}});
     } else if (event.target.id === 'customer_birth') {
       this.setState({customer: {...this.state.customer, customer_birth: name}});
     } else if (event.target.id === 'customer_age') {
       this.setState({customer: {...this.state.customer, customer_age: name}});
     } else if (event.target.id === 'customer_mobile') {
       this.setState({customer: {...this.state.customer, customer_mobile: name}});
     } else if (event.target.id === 'car_gear_id') {
       this.setState({customer: {...this.state.customer, car_gear_id: name}});
     } else if (event.target.id === 'car_color_id') {
       this.setState({customer: {...this.state.customer, car_color_id: name}});
     } else if (event.target.id === 'source_customer') {
       this.setState({customer: {...this.state.customer, source_customer: name}});
     }
   //}
  }

  handleCarYearChange(event) {
    var value = event.target.value;
    if (event.target.id === 'car_year_start') {
      this.setState({customer: {...this.state.customer, car_year_start_id: value}});
    } else if(event.target.id === 'car_year_final'){
      this.setState({customer: {...this.state.customer, car_year_final_id: value}});
    }
  }

  convertCCtoBB(cc_date) { // "1972-10-31"
    let date_arr=cc_date.split('-');
    let dd = date_arr[2];
    let mm = date_arr[1];
    let yyyy = Number(date_arr[0])+543;

    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return dd+'-'+mm+'-'+yyyy ;
    }
    return moment() ;
  }

  handleContactDate(date) {
    this.setState({customer: {...this.state.customer, contact_date: date}});
  }

  render () {
    let car_brand_list = this.state.car_brands.map((car_brand, index) => {
      return (
        <option key={index} value={car_brand.car_brand_id}>{car_brand.car_brand_name}</option>
      )
    })
    let car_brand_model = this.state.car_models.map((car_model, index) => {
      return (
        <option key={index} value={car_model.car_model_id}>{car_model.car_model_name}</option>
      )
    })

    let car_year_list = this.state.car_years.map((car_year, index) => {
      return (
        <option key={index} value={car_year.car_year_id}>{car_year.car_year_name}</option>
      )
    })

    let car_gear_list = this.state.car_gears.map((car_gear, index) => {
      return (
        <option key={index} value={car_gear.car_gear_id}>{car_gear.car_gear_name}</option>
      )
    })

    let car_color_list = this.state.car_colors.map((car_color, index) => {
      return (
        <option key={index} value={car_color.car_color_id}>{car_color.car_color_name}</option>
      )
    })

    return (
      <div className={this.props.actionType==="บันทึก"? "card card-accent-primary" : "card card-accent-warning"}>
        <form action="" method="post" onSubmit={this.handleOnSubmitCustomerFindCar}>
        <div className="card-header">
          <strong className="text-title">{this.props.actionType==="บันทึก"? "เพิ่ม" : "แก้ไข"}ลูกค้าหารถ</strong>
        </div>
        <div className="card-block">
          <div className="row">

            <div className="form-group col-sm-4">
              <label htmlFor="car_date_in">วันที่เข้ามาติดต่อ </label>
              <br/>
              <DatePicker
                type="text"
                className="form-control col-sm-12"
                id="contact_date"
                name="contact_date"
                placeholder=""
                selected={Object.keys(this.state.customer).length ===0 ? new Date(): this.state.customer.contact_date===undefined ? new Date() : new Date(this.state.customer.contact_date)}
                dateFormat="yyyy-MM-dd"
                onChange={this.handleContactDate}
              />
            </div>


            <div className="form-group col-sm-4">
              <label htmlFor="customer_name">ชื่อ</label>
              <input type="text"
                     className="form-control"
                     id="customer_name"
                     placeholder=""
                     value={this.state.customer.customer_name || ''}
                     onChange={this.onInputChangeCustomer}
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="customer_lastname">นามสกุล</label>
              <input type="text"
                     className="form-control"
                     id="customer_lastname"
                     placeholder=""
                     value={this.state.customer.customer_lastname || ''}
                     onChange={this.onInputChangeCustomer}
              />
            </div>

            <div className="form-group col-sm-6">
              <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>
              <input type="text"
                     className="form-control hidden"
                     id="customer_mobile_old-bk"
                     placeholder=""
                     // value={this.state.customer.customer_mobile || ''}
                     // onChange={this.onInputChangeCustomer}
              />

              <MaskedInput
                  mask="111-111-1111"
                  className="form-control"
                  id="customer_mobile"
                  placeholder=""
                  value={this.state.customer.customer_mobile || ''}
                  onChange={this.onInputChangeCustomer}
              />
            </div>

            <div className="form-group col-sm-6">
              <label htmlFor="source_customer">แหล่งที่มา</label>
              <input type="text"
                     className="form-control"
                     id="source_customer"
                     placeholder=""
                     value={this.state.customer.source_customer || ''}
                     onChange={this.onInputChangeCustomer}
              />
            </div>

            <div className="form-group col-sm-12">
              <label htmlFor="customer_address">ที่อยู่ปัจจุบัน</label>
              <input type="text"
                     className="form-control"
                     id="customer_address"
                     placeholder=""
                     value={this.state.customer.customer_address || ''}
                     onChange={this.onInputChangeCustomer}
              />
            </div>

            <div className="form-group col-sm-6 hidden">
              <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>
              <input type="text"
                     className="form-control hidden"
                     id="customer_card_id_old-bk"
                     // maxLength="13"
                     placeholder=""
                     // value={this.state.customer.customer_card_id || ''}
                     // onChange={this.onInputChangeCustomer}
              />

              <MaskedInput
                  mask="1-1111-11111-11-1"
                  className="form-control"
                  id="customer_card_id"
                  maxLength="20"
                  placeholder=""
                  value={this.state.customer.customer_card_id || ''}
                  onChange={this.onInputChangeCustomer}
              />
            </div>

            <div className="form-group col-sm-6 hidden">
              <label htmlFor="customer_birth_text">วันเดือนปีเกิด (พ.ศ.)</label>
              {/*<MaskedInput
                mask="11-11-1111"
                name="customer_birth_text"
                placeholder="dd-mm-yyyy"
                onChange={this.onMaskedDateCustomerNew1}
                className="form-control"
                value={this.state.customer_birth_mask}
              />
              <input className="form-control hidden"
                     type="text"
                     id="customer_birth"
                     name="customer_birth"
                     ref="customer_birth"
                     value={this.state.customer_birth}
                     onChange={()=>{}}
              />
              <DatePicker hidden
                                selected={this.state.startDateCustomerNew1}
                                onChange={this.handleDateCustomerNew1}
                                dateFormat="yyyy-MM-dd"
                                   type="text"
                                   className="form-control"
                                   id="customer_birth"
                                   name="customer_birth"
                                   placeholder=""
                            />*/}
            </div>
            <div className="form-group col-sm-6 hidden">
              <label htmlFor="customer_age">อายุ</label>
              <input type="number"
                     className="form-control"
                     id="customer_age"
                     placeholder=""
                     value={this.state.customer.customer_age || ''}
                     onChange={this.onInputChangeCustomer}
              />
            </div>



          </div>

          <div className="row" >
            <div className="form-group col-12" style={styles.bottomLine}>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <strong>ข้อมูลรถที่ลูกค้าต้องการ</strong></div>

            <div className="form-group col-6">
              <label htmlFor="car_brand_id">ยี่ห้อ</label>
              <select className="form-control"
                      id="car_brand_id"
                      name="car_brand_id"
                      onChange={this.handleCarBrandChange}
                      value={this.state.customer.car_brand_id || ''}
              >
                <option value="">เลือกยี่ห้อรถ</option>
                {car_brand_list}
              </select>
            </div>

            <div className="form-group col-6">
              <label htmlFor="car_model_id">รุ่นรถ</label>
              <select className="form-control"
                      id="car_model_id"
                      name="car_model_id"
                      ref="car_model_id"
                      onChange={this.handleCarModelChange}
                      value={this.state.customer.car_model_id || ''}
              >
                <option value="">เลือกรุ่นรถ</option>
                {car_brand_model}
              </select>
            </div>

            <div className="form-group col-sm-6">
              <label htmlFor="gear">ระบบเกียร์รถ</label>
              <select
                className="form-control"
                id="car_gear_id"
                name="car_gear_id"
                onChange={this.onInputChangeCustomer}
                value={this.state.customer.car_gear_id || ''}
              >
                {car_gear_list}
              </select>
            </div>

            <div className="form-group col-sm-6">
              <label htmlFor="color">สี</label>
              <select className="form-control"
                      id="car_color_id"
                      name="car_color_id"
                      onChange={this.onInputChangeCustomer}
                      value={this.state.customer.car_color_id || ''}
              >
                {car_color_list}
              </select>
            </div>


            <div className="form-group col-6">
              <label htmlFor="year">รถปี ค.ศ. เริ่ม</label>
              <select className="form-control"
                      id="car_year_start"
                      name="car_year_start"
                      onChange={this.handleCarYearChange}
                      value={this.state.customer.car_year_start_id || ''}
              >
                {car_year_list}
              </select>
            </div>

            <div className="form-group col-6">
              <label htmlFor="year">รถปี ค.ศ. สิ้นสุด</label>
              <select className="form-control"
                      id="car_year_final"
                      name="car_year_final"
                      onChange={this.handleCarYearChange}
                      value={this.state.customer.car_year_final_id || ''}
              >
                {car_year_list}
              </select>
            </div>

          </div>
        </div>
          <div className="card-footer text-right">
            <button type="reset"
                    className="btn btn-sm btn-danger mr-2"
                    onClick={this.props.onToggle}
            >
              <i className="fa fa-close"/> ยกเลิก
            </button>

            <button type="submit" className={this.props.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}>
              <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "mr-2 fa fa-save"}/>
              {this.props.actionType}
            </button>

          </div>
        </form>
      </div>
    )
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerFindCarAdd)
